import { useState } from "react";
import { Headline } from "@servicetitan/design-system";

import { IHeaderProps } from "../../types/shared";

export const Header = ({
  googleBusinessProfileMediaUrl,
  googleBusinessProfileName,
  style,
}: IHeaderProps) => {
  const [isValidMediaUrl, setIsValidMediaUrl] = useState(true);

  return (
    (googleBusinessProfileMediaUrl && isValidMediaUrl && (
      <img
        alt={googleBusinessProfileName}
        data-cy="gbp-image"
        onError={() => {
          setIsValidMediaUrl(false);
        }}
        src={googleBusinessProfileMediaUrl}
        style={style}
      />
    )) || <Headline data-cy="gbp-name">{googleBusinessProfileName}</Headline>
  );
};
