import { getReserveWithGoogleToken } from "./getReserveWithGoogleToken";

export const getWidgetOrigin = (clientKey: string): string => {
  const devEnv = process.env.REACT_APP_DEV_ENV || false;
  const protocol = process.env.REACT_APP_WIDGET_URL_PROTOCOL || "";
  const url = process.env.REACT_APP_WIDGET_URL || "";

  if (devEnv) {
    return protocol + url;
  } else {
    return protocol + clientKey + url;
  }
};

export const getWidgetUrl = (
  rwgConfig:
    | {
        clientKey: string;
        flowKey: string;
      }
    | {
        schedulerSrc: string;
      }
): string => {
  if ("schedulerSrc" in rwgConfig) {
    const url = new URL(rwgConfig.schedulerSrc);

    const splitRoute = window.location.pathname.split("/");
    const gbpIdOrToken = splitRoute[splitRoute.length - 1].split("?")[0];
    const isGbpId = gbpIdOrToken.startsWith("gbp_");

    if (!isGbpId) {
      url.searchParams.set("schedulerToken", gbpIdOrToken);
    }

    const rwgToken = getReserveWithGoogleToken();

    if (rwgToken) {
      url.searchParams.set("rwgToken", rwgToken);
    }

    url.searchParams.set("client_url", window.location.toString());

    url.searchParams.set("client_referrer", document.referrer);

    return url.toString();
  }

  const { clientKey, flowKey } = rwgConfig;

  const data = {
    api_key: clientKey,
    widget_view_mode: "page",
  };

  const searchParams = new URLSearchParams(data);

  if (flowKey) {
    searchParams.append("flow_key", flowKey);
  }

  const rwgToken = getReserveWithGoogleToken();

  if (rwgToken) {
    searchParams.set("rwg_token", rwgToken);
  }

  return `${getWidgetOrigin(clientKey)}/?${searchParams.toString()}`;
};
