import { Stack } from "@servicetitan/design-system";
import styled from "styled-components/macro";
import { StyledFooterText } from "../../shared/styled";

export const FooterText = styled(StyledFooterText)`
  font-weight: 600;
  line-height: 15px;
`;

export const FrameStackItem = styled(Stack.Item)`
  flex: 1;
  height: 100%;
  width: 100%;
`;
