import { useEffect, useRef, useState } from "react";
import { getWidgetOrigin } from "../utils/getWidgetUrl";
import { collectReserveWithGoogleConversion } from "../utils/collectReserveWithGoogleConversion";

export const useMountWidget = (
  iFrame: any,
  clientKey?: string,
  schedulerSrc?: string
): boolean => {
  const [isCardVisible, setIsCardVisible] = useState<boolean>(true);
  const conversionSentRef = useRef<boolean>(false);

  useEffect(() => {
    const handleMessageFromChild = (event: any) => {
      // TODO: Validate valid origins

      switch (event.data.type) {
        case "se-app-ready":
          if (iFrame.current) {
            setIsCardVisible(false);
            iFrame.current.contentWindow?.postMessage(
              {
                type: "se-show-modal",
              },
              clientKey ? getWidgetOrigin(clientKey) : schedulerSrc
            );
          }

          break;
        case "se-event":
          if (event.data.eventName === "BookingBooked") {
            if (!conversionSentRef.current) {
              conversionSentRef.current = true;
              collectReserveWithGoogleConversion();
            }
          }

          break;
        default:
          // Do nothing
          break;
      }
    };

    window.addEventListener("message", handleMessageFromChild);

    return () => {
      window.removeEventListener("message", handleMessageFromChild);
    };
  }, [iFrame, clientKey, schedulerSrc]);

  return isCardVisible;
};
