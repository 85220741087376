export function getReserveWithGoogleToken(): string | null {
  const urlObject = new URL(window.location.href);
  const urlSearchParams = urlObject.searchParams;

  const rwgToken = urlSearchParams.get("rwg_token");

  if (rwgToken) {
    const rwgTokenVal = rwgToken;

    document.cookie = `_rwg_token=${rwgTokenVal};max-age=2592000;domain=${window.location.hostname};path=/`;

    return rwgTokenVal;
  }

  const rwgTokenCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_rwg_token="));

  let rwgTokenVal = null;

  if (typeof rwgTokenCookie !== "undefined") {
    // eslint-disable-next-line prefer-destructuring
    rwgTokenVal = rwgTokenCookie.split("=")[1];
  }

  return rwgTokenVal;
}
