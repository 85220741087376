import { Frame, Stack, Spinner } from "@servicetitan/design-system";

export const InitialPage = () => {
  return (
    <Frame>
      <Stack
        alignItems="center"
        className="m-x-auto"
        direction="column"
        justifyContent="center"
      >
        <Spinner className="p-y-2" size="large" />
      </Stack>
    </Frame>
  );
};
