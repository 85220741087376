import { IStyledFrame } from "../types/shared";
import styled from "styled-components/macro";
import { BodyText, Eyebrow, Stack } from "@servicetitan/design-system";
import { light } from "@servicetitan/anvil-themes";

export const CardBodyText = styled(BodyText)`
  text-align: center;
`;

export const Footer = styled(Stack.Item)`
  background-color: #e9edf0;
  border-top-color: ${light.colors.border.grey};
`;

export const FrameItem = styled(Stack.Item)`
  flex: 1;
`;

export const StyledFooterText = styled(Eyebrow)`
  color: #8692a5;
`;

export const LocationText = styled(BodyText)`
  color: #303944;
`;

// eslint-disable-next-line
export const StyledIFrame = styled.iframe<IStyledFrame>`
  // set color scheme to match scheduler to prevent opaque background issue
  ${({ isDesktopV2 }) =>
    isDesktopV2 &&
    `
  @media (prefers-color-scheme: dark) {
    color-scheme: dark !important;
  }`}

  border: none;
  ${({ isVisible }) =>
    isVisible
      ? `
      height: 100%;
      width: 100%;
    `
      : `
      height: 0%;
      width: 0%;
    `}
`;
