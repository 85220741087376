export function collectReserveWithGoogleConversion(): void {
  const partnerId = process.env.REACT_APP_RWG_PARTNER_ID;
  const endpoint = process.env.REACT_APP_RWG_CONVERSION_TRACKING_COLLECTION_URI;

  if (!partnerId || !endpoint) {
    return;
  }

  const rwgTokenCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_rwg_token="));

  if (typeof rwgTokenCookie !== "undefined") {
    const rwgTokenVal = rwgTokenCookie.split("=")[1];

    if (rwgTokenVal) {
      const rwgConversionTokenVal = rwgTokenVal.split(":")[0];
      fetch(endpoint, {
        body: JSON.stringify({
          conversion_partner_id: partnerId,
          // Since we don't allow the user to switch merchants after they've
          // clicked the Book Now button (/gotten an RWG token), we should
          // always indicate the merchant has not changed, per
          // https://developers.google.com/actions-center/verticals/appointments/redirect/reference/tutorials/migrating-to-conversion-tracking-v2#changes_to_sending_conversion_data
          merchant_changed: 2,
          rwg_token: rwgConversionTokenVal,
        }),
        method: "POST",
      });
    }
  }
}
