import { Stack } from "@servicetitan/design-system";
import { WIDGET_BOX_SHADOW_SIZE_PX } from "../../shared/constants";
import styled from "styled-components/macro";
import { IStyledFrame } from "../../types/shared";
import { ReactComponent as CardBanner } from "../../assets/cardBanner.svg";

interface IFrameStackItem extends IStyledFrame {
  absolute: boolean;
  top: number;
}

export const DesktopCardBanner = styled(CardBanner)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const FrameStackItem = styled(Stack.Item)<IFrameStackItem>`
  ${({ absolute, top }) =>
    absolute &&
    `
      position: absolute;
      top: ${top - WIDGET_BOX_SHADOW_SIZE_PX}px;
    `}

  ${({ isVisible }) =>
    isVisible
      ? `
  flex: 1;
  height: 100%;
  width: 100%;
`
      : `
  height: 0%;
  width: 0%;
`}
`;
