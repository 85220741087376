import { BodyText, Frame, Headline, Stack } from "@servicetitan/design-system";
import { ReactComponent as Logo } from "../../assets/stLogo.svg";
import { ReactComponent as NotFoundIcon } from "../../assets/notFound.svg";
import { useEffect } from "react";

export const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Not Found";
  });

  return (
    <Frame
      header={
        <Stack class="m-y-3" justifyContent="center">
          <Logo />
        </Stack>
      }
    >
      <Stack
        alignItems="center"
        className="m-x-auto"
        direction="column"
        justifyContent="center"
      >
        <NotFoundIcon data-cy="not-found-icon" />
        <Headline size="large">Oooops...</Headline>
        <BodyText size="medium">
          We can&apos;t find the page you are looking for
        </BodyText>
      </Stack>
    </Frame>
  );
};
